import { Box, Center, Text, useToast } from "@chakra-ui/react";
import Header from "../home/header";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "./textField";
import { RoutePaths } from "../../utils/routes/routePaths";


const NewPassword = () => {
  const { token } = useParams<string>();
   const navigate = useNavigate();
   const toast = useToast();
   const [login, setLogin] = useState({
     loading: false,
     errorMessage: " ",
   });
  const url =
    process.env.REACT_APP_PROD_ENV === "false"
      ? "http://localhost:8000"
      : process.env.REACT_APP_SERVER_HOST;
  const submitLogin = async (values: any) => {
     try {
       setLogin({
         ...login,
         loading: true,
       });
      //  console.log(body)
       const res = await fetch(
         `${url}/set_password/?token=${values.token}`,
         {
           method: "PUT",
           headers: { "Content-Type": "application/json" },
           body: JSON.stringify({ new_password: values.new_password }),
         }
       );
      //  const user = await res.json();
       
       setLogin({ ...login, loading: false });
       if (res.status === 202) {
         toast({
           title: "Login Successful.",
           position: "top-right",
           status: "success",
           duration: 9000,
           isClosable: true,
         });
         navigate(RoutePaths.LOGIN);
       } else if (res.status === 401) {
         toast({
           title: "Expired Link",
           position: "top-right",
           status: "error",
           duration: 9000,
           isClosable: true,
         });
       } else {
         toast({
           title: "Connection Error",
           position: "top-right",
           status: "error",
           duration: 9000,
           isClosable: true,
         });
       }
     } catch (error) {
       toast({
         title: "Connection Error",
         position: "top-right",
         status: "error",
         duration: 9000,
         isClosable: true,
       });
     }
   };
   const { loading } = login;
  return (
    <Box>
      <Header />
      <Box
        bg="brand.primary"
        shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
        pt={5}
        h={{ base: "100vh", md: "none", lg: "100vh" }}
      >
        <Box
          bgImage="url('/images/Group 14.png')"
          bgPosition="center"
          width="100%"
          h={{ base: "none", md: "none", lg: "100%" }}
          overflowY="hidden"
          bgRepeat="no-repeat"
          bgSize={{ base: "cover", md: "contain" }}
        >
          <Center py={{ base: "15%", md: "5%" }}>
            <Box
              pt={{ base: "3", lg: "3" }}
              bg="#FFFFFF"
              width={{ base: "80%", lg: "25%" }}
              borderRadius="2xl"
              filter="drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5))"
            >
              <Text
                fontSize={{ base: "lg", lg: "2xl" }}
                fontWeight="bold"
                p={5}
                color="#6165D7B2"
              >
                Create New Password
              </Text>

              <Box px={5} pb={10}>
                <Formik
                  initialValues={{
                    new_password: "",
                    token: token,
                  }}
                  validationSchema={Yup.object({
                    new_password: Yup.string()
                      .required("password is required")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                      ),
                    confirm_password: Yup.string().when("password", {
                      is: (val: any) => (val && val.length > 0 ? true : false),
                      then: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),
                    }),
                  })}
                  onSubmit={(values, actions) => {
                    submitLogin(values);
                    // actions.resetForm();
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        mt={8}
                        name="new_password"
                        placeholder="New Password"
                        type="password"
                      />
                      <TextField
                        mt={8}
                        name="confirm_password"
                        placeholder="Confirm Password"
                        type="password"
                      />

                      <Box
                        mt={10}
                        as="button"
                        type="submit"
                        bg="brand.primary"
                        color="#00000"
                        borderRadius="2xl"
                        width="100%"
                        p={{ base: "3", md: "3", lg: "3" }}
                        fontWeight="bold"
                        fontSize="lg"
                      >
                        {loading ? " Sending..." : " RESET MY PASSWORD"}
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Center>
          <Text textAlign="center" fontSize={{ base: "sm" }}>
            &#169; New Horizons Capital . 2022.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPassword;
