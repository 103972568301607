import { Box, Checkbox, HStack, InputGroup, InputRightElement, Link, Stack, Text } from '@chakra-ui/react'
import { Formik } from 'formik';
import { useToast } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { RoutePaths } from '../../utils/routes/routePaths';
import Header from '../home/header'
import TextField from './textField';
import { useEffect, useState } from "react";
import { useLoginMutation } from '../../utils/services/api';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';


const Login = () => {
  const [login, { data, isSuccess, isError, error, isLoading }] =
    useLoginMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  useEffect(() => {
    try {
      if (isSuccess) {
        localStorage.setItem(
          "token",
          JSON.stringify(data?.access_token)
        );
        localStorage.setItem("access", "Authorized");
        navigate(RoutePaths.DASHBOARD);
        toast({
          title: "Login Successful.",
          position: "top-right",
          status: "success",
          isClosable: true,
        });
      }
    } catch (e) {
       toast({
         title: "Connection Error",
         position: "top-right",
         status: "error",
         isClosable: true,
       });
    }
  }, [data,isSuccess,toast,navigate]);

  useEffect(() => {
    try {
      if (isError) {
        toast({
          title: (error as any)?.data.detail,
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: "Connection Error",
        position: "top-right",
        status: "error",
        isClosable: true,
      });
    }
  }, [isError, error, toast]);

  return (
    <Box>
      <Header />

      <Box
        bg="brand.primary"
        shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
        pt={10}
        width="100%"
        h={{ base: "100vh", lg: "100vh" }}
      >
        <Box
          pt={10}
          bgImage={{ base: "none", md: "url('/images/Group 14.png')" }}
          bgPosition="center"
          width="100%"
          // h={{ base: "none", md: "auto" }}
          bgRepeat="no-repeat"
          bgSize={{ base: "cover", md: "contain" }}>
          <Stack
            direction={{ base: "column", md: "row" }}
            pb={10}
            px={{ base: "5" }}
            pr={{ lg: "350px" }}
            justifyContent="center"
            spacing={{ base: "none", md: "150px" }}
          >
            <Box display={{ base: "none", lg: "block" }}>
              <Text fontSize="40px" fontWeight="extrabold" pt={24}>
                Welcome <br />
                back.
              </Text>
            </Box>
            <Box
              pt={{ base: "3", md: "10" }}
              bg="#FFFFFF"
              width={{ base: "100%", lg: "340px" }}
              height={{base:"",md:"400px"}}
              borderRadius="2xl"
              filter="drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5))"
            >
              <Text fontSize="3xl" fontWeight="bold" px={5} color="#6165D7B2">
                Sign In
              </Text>

              <Box pt={2} px={5} width={{ base: "", lg: "100%" }}>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                    password: Yup.string()
                      .max(255)
                      .required("Password is required"),
                  })}
                  onSubmit={(values, actions) => {
                    login(values);
                    // actions.resetForm();
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        mt={8}
                        name="email"
                        placeholder="Email"
                        type="email"
                      />
                      <InputGroup size="md">
                        <TextField
                          mt={8}
                          name="password"
                          position="relative"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.password || ""}
                          onChange={formik.handleChange}
                        />
                        <InputRightElement
                          width="4.5rem"
                          position="absolute"
                          mt={8}
                          onClick={handleClick}
                          cursor="pointer"
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </InputRightElement>
                      </InputGroup>

                      <HStack pt={5}>
                        <Checkbox></Checkbox>
                        <Text>Stay signed in</Text>
                      </HStack>

                      <Box
                        as="button"
                        type="submit"
                        bg="brand.primary"
                        color="#00000"
                        borderRadius="2xl"
                        mt={4}
                        width="100%"
                        p={{ base: "2", md: "3", lg: "3" }}
                        fontWeight="bold"
                        fontSize="lg"
                      >
                        {isLoading ? " Signing in..." : "Sign In"}
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
              <Box mt={5}>
                <Link
                  color="#6165D7B2"
                  px={5}
                  style={{ textDecoration: "none" }}
                  href="/forgot_password"
                >
                  Forgot Password?
                </Link>
              </Box>

              <HStack px={5} pt={5} pb={10}>
                {/* <Text>Dont have an account?</Text>
                <Link
                  color="#6165D7B2"
                  style={{ textDecoration: "none" }}
                  href="/signup"
                >
                  SIGN UP
                </Link> */}
              </HStack>
            </Box>
          </Stack>
        </Box>
        <Text py={10} textAlign="center" fontSize={{ base: "sm" }}>
          &#169; New Horizons Capital . 2022.
        </Text>
      </Box>
    </Box>
  );
}

export default Login