
import Layout from '../../components/layout/layout'
import Profile from '../../components/profile/profile'

const ProfilePage = () => {
    return (
      <Layout>
          <Profile/>
      </Layout>
  )
}

export default ProfilePage