

export function isPayoutButtonActive(): boolean {
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDate();
  const currentHour = currentDate.getUTCHours();
  const isActiveDay = currentDay === 13 || currentDay === 27;
  const isActiveTime = currentHour >= 9 && currentHour < 15; // assuming the active period is from 9am to 3pm UTC time
  return isActiveDay && isActiveTime;
}

export function getDaysUntilNextActiveDay(): number {
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDate();
  const currentHour = currentDate.getUTCHours();

  // Determine the next active day
  let activeDay: number;
  if (currentDay < 13) {
    activeDay = 13;
  } else if (currentDay < 27) {
    activeDay = 27;
  } else {
    activeDay = 13; // Next month's 13th
  }

  // Determine if the button is currently active
  const isActiveDay = currentDay === 13 || currentDay === 27;
  const isActiveTime = currentHour >= 9 && currentHour < 15; // assuming the active period is from 9am to 3pm UTC time
  if (isActiveDay && isActiveTime) {
    return 0; // Button is currently active
  } else {
    // Calculate the number of days until the next active day
    const daysUntilNextActiveDay = activeDay - currentDay;
    if (daysUntilNextActiveDay < 0) {
      return 30 + daysUntilNextActiveDay; // Next month's active day
    } else {
      return daysUntilNextActiveDay;
    }
  }
}
