import { Box, Flex, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import Slider from "../utils/carousel";
import Trade from "./trade";
import { useState } from "react";

const SelectAccount = () => {
  const [capital, setCapital] = useState(0);
  return (
    <Box>
      <Box bg="#000000" id="selectAccount">
        <Flex justifyContent="space-around" mt={20} py={{ base: "10%" }}>
          <SimpleGrid columns={[1, 1, 1, 2]} spacing={28}>
            <Box bg="#000000" pt={10} px={{ base: "", md: "20" }}>
              <VStack>
                <Text
                  color="white"
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="extrabold"
                  textAlign="center"
                >
                  Select Your <br /> Preferred Capital
                </Text>
              </VStack>
              <HStack
                spacing={{ base: "5", lg: "28" }}
                mt={8}
                justifyContent="space-around"
              >
                <Box
                  as="button"
                  bg="linear-gradient(90deg, rgba(97, 101, 215, 0.31) 0%, rgba(69, 161, 245, 0.53) 100%)"
                  pr={{ base: "3", lg: "8" }}
                  py={{ base: "3", lg: "6" }}
                  pl={{ base: "5", lg: "5" }}
                  onClick={() => setCapital(0)}
                >
                  <Text
                    fontSize={{ base: "large", md: "2xl" }}
                    fontWeight="extrabold"
                    color="#FFFFFF"
                  >
                    $25,000
                  </Text>
                </Box>
                <Box
                  as="button"
                  bg="linear-gradient(90deg, rgba(97, 101, 215, 0.31) 0%, rgba(69, 161, 245, 0.53) 100%)"
                  pr={{ base: "3", lg: "8" }}
                  py={{ base: "3", lg: "6" }}
                  pl={{ base: "5", lg: "5" }}
                  onClick={() => setCapital(1)}
                >
                  <Text
                    fontSize={{ base: "larger", md: "2xl" }}
                    fontWeight="extrabold"
                    color="#FFFFFF"
                  >
                    $50,000
                  </Text>
                </Box>
              </HStack>
              <HStack
                spacing={{ base: "5", lg: "28" }}
                mt={8}
                justifyContent="space-around"
              >
                <Box
                  as="button"
                  bg="linear-gradient(90deg, rgba(97, 101, 215, 0.31) 0%, rgba(69, 161, 245, 0.53) 100%)"
                  p={{ base: "3", lg: "5" }}
                  onClick={() => setCapital(2)}
                >
                  <Text
                    fontSize={{ base: "large", md: "2xl" }}
                    fontWeight="extrabold"
                    color="#FFFFFF"
                  >
                    $100,000
                  </Text>
                </Box>
                <Box
                  as="button"
                  bg="linear-gradient(90deg, rgba(97, 101, 215, 0.31) 0%, rgba(69, 161, 245, 0.53) 100%)"
                  p={{ base: "3", lg: "5" }}
                  onClick={() => setCapital(3)}
                >
                  <Text
                    fontSize={{ base: "large", md: "2xl" }}
                    fontWeight="extrabold"
                    color="#FFFFFF"
                  >
                    $200,000
                  </Text>
                </Box>
              </HStack>
              <Text
                mt={10}
                pb={{ lg: "8" }}
                color="white"
                px={{ base: "6%" }}
                display={{ base: "none", lg: "block" }}
                fontSize={{ base: "md", md: "x-large" }}
              >
                *Minimum trading days is the minimum number of days in your
                evaluation and verification stages that you are required to
                execute a trade. <br /> <br />
                *Minimum profit days is the minimum number of days in your
                evaluation and verification stages that you are required to
                close your trades in profit
              </Text>
            </Box>
            <Box>
              <Slider capital={capital} />
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>
      <Trade />
    </Box>
  );
};

export default SelectAccount;
