


export const RoutePaths = Object.freeze({
  BASE_URL: "/",
  FAQ: "/faq",
  LOGIN: "/login",
  PAYMENT: "/payment_successful",
  SIGNUP: "/signup/:token",
  FORGOT_PASSWORD: "/forgot_password",
  NEW_PASSWORD: "/password/:token",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PAYOUT: "/payout",
});
