import {
  Box,
  Center,
  HStack,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Formik} from "formik";
import Header from "../home/header";
import * as Yup from "yup";
import TextField from "./textField";
import { useEffect, useState } from "react";
import SelectField from "./selectField";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "../../utils/routes/routePaths";
import { useSignUpUserMutation } from "../../utils/services/api";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";






const Signup = () => {
  const [signUpUser,{isSuccess,isError, error, isLoading}] = useSignUpUserMutation()
  const navigate = useNavigate();
  const toast = useToast()
  const { token } = useParams<string>();
  const [getCountry, setCountry] = useState({
    loading: false,
    countries: [],
    errorMessage:" "
  })
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);


  useEffect(() => {
    const getCountryData = async () => {
      try {
        setCountry({
          ...getCountry,
          loading: true,
        });
        const reqData = await fetch("https://restcountries.com/v3.1/all");
        const resData = await reqData.json();
        setCountry({
          ...getCountry,
          countries: resData,
          loading: false,
        });
      } catch (error) {
        setCountry({
          ...getCountry,
          loading: false,
          errorMessage: "something went wrong",
        });
      }
    };
    getCountryData();
    // eslint-disable-next-line
  }, [])
  const {  countries } = getCountry

 
const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{4,14})(( x| ext)\d{1,5}){0,1}$/;

const validationSchema = Yup.object({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_no: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),

  country: Yup.string().required("Country is required"),
  password: Yup.string()
    .required("password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: Yup.string().when("password", {
    is: (val: any) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  }),
});
  useEffect(() => {
    if (isSuccess) {
       toast({
         title: "Account Created Successful.",
         position: "top-right",
         status: "success",
         duration: 9000,
         isClosable: true,
       });
       navigate(RoutePaths.LOGIN);
    }
  }, [isSuccess,toast,navigate])


  useEffect(() => {
    try {
      if (isError) {
        toast({
          title: (error as any)?.data.detail,
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: "Connection Error",
        position: "top-right",
        status: "error",
        isClosable: true,
      });
    }
  }, [isError, error, toast]);
  const submitData = async (values:any) => {
    delete values["confirm_password"];
    signUpUser(values)
  
}

  return (
    <Box>
      <Header />
      <Box
        bg="brand.primary"
        shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
        // pt={5}

        h={{ base: "100vh", md: "none", lg: "auto" }}
      >
        <Box
          pt={10}
          bgImage={{ base: "none", md: "url('/images/Group 14.png')" }}
          bgPosition="center"
          width="100%"
          h={{ base: "none", md: "none", lg: "900px" }}
          overflowX="hidden"
          bgRepeat="no-repeat"
          bgSize={{ base: "cover", md: "contain" }}
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            pb={10}
            px={{ base: "5" }}
            pl={{ lg: "36" }}
            spacing={{ base: "none", lg: "32" }}
          >
            <Box display={{ base: "none", lg: "block" }} width="25%">
              <Text
                fontSize="3xl"
                textAlign="left"
                fontWeight="extrabold"
                pt={52}
              >
                Take the first step towards unleashing your full potential.
              </Text>
            </Box>
            <Box
              pt={{ base: "3", lg: "3" }}
              bg="#FFFFFF"
              width={{ base: "100%", lg: "30%" }}
              borderRadius="2xl"
              filter="drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5))"
            >
              <Text fontSize="3xl" fontWeight="bold" px={5} color="#6165D7B2">
                Sign Up
              </Text>
              <VStack px={5} pt={5}>
                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    country: "",
                    phone_no: "",
                    password: "",
                    confirm_password: "",
                    token,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    submitData(values);
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        mt={8}
                        name="first_name"
                        placeholder="First Name"
                        type="text"
                        value={formik.values.first_name || ""}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        mt={8}
                        name="last_name"
                        placeholder="Last Name"
                        type="text"
                        value={formik.values.last_name || ""}
                        onChange={formik.handleChange}
                      />
                      <SelectField
                        name="country"
                        placeholder="United States"
                        mt={8}
                      >
                        {countries
                          ?.sort((a: any, b: any) =>
                            a.name.common.localeCompare(b.name.common)
                          )
                          .map((item: any, index: number) => (
                            <option key={index} value={item?.name.common}>
                              {item?.name.common}
                            </option>
                          ))}
                      </SelectField>
                      <TextField
                        mt={8}
                        name="phone_no"
                        placeholder="+15556667777"
                        type="tel"
                        value={formik.values.phone_no || ""}
                        onChange={formik.handleChange}
                      />
                      <InputGroup size="md">
                        <TextField
                          mt={8}
                          name="password"
                          position="relative"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.password || ""}
                          onChange={formik.handleChange}
                        />
                        <InputRightElement
                          width="4.5rem"
                          position="absolute"
                          mt={8}
                          onClick={handleClick}
                          cursor="pointer"
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      <InputGroup size="md">
                        <TextField
                          mt={8}
                          name="confirm_password"
                          placeholder="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          value={formik.values.confirm_password || ""}
                          onChange={formik.handleChange}
                        />
                        <InputRightElement
                          width="4.5rem"
                          position="absolute"
                          mt={8}
                          onClick={handleClickConfirmPassword}
                          cursor="pointer"
                        >
                          {showConfirmPassword ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </InputRightElement>
                      </InputGroup>

                      <Text fontSize="md" mt={5}>
                        By signing up, you agree to our{" "}
                        <Box as="span" color="#6165D7B2" fontSize="md">
                          Terms of use
                        </Box>
                      </Text>

                      <Center mt={5}>
                        <Box
                          as="button"
                          type="submit"
                          bg="brand.primary"
                          color="#00000"
                          borderRadius="2xl"
                          mt={4}
                          width="60%"
                          p={{ base: "2", md: "3", lg: "3" }}
                          fontWeight="bold"
                          fontSize="lg"
                        >
                          {isLoading ? "Signing Up" : "SIGN UP"}
                        </Box>
                      </Center>
                    </form>
                  )}
                </Formik>
              </VStack>
              <HStack px={5} pt={7} pb={10}>
                {/* <Text fontSize={{ base: "sm" }}>Already have an account?</Text>
                <Link
                  style={{ textDecoration: "none" }}
                  href="/login"
                  fontSize={{ base: "sm" }}
                  color="#6165D7B2"
                >
                  SIGN IN
                </Link> */}
              </HStack>
            </Box>
          </Stack>
          <Text py={10} textAlign="center" fontSize={{ base: "sm" }}>
            &#169; New Horizons Capital . 2022.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Signup;
