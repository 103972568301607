import {
  Box,
  Center,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Header from "../home/header";
import TextField from "./textField";

const ForgotPassword = () => {
  const toast = useToast();
  const [login, setLogin] = useState({
    loading: false,
    errorMessage: " ",
  });
  const url =
    process.env.REACT_APP_PROD_ENV === "false"
      ? "http://localhost:8000"
      : process.env.REACT_APP_SERVER_HOST;
  const submitLogin = async (values: any) => {
    try {
      setLogin({
        ...login,
        loading: true,
      });
      const res = await fetch(`${url}/password`, {
        method: "POST",
       headers: { "Content-Type": "application/json" },

      // credentials: "include",
      body: JSON.stringify(
        values)
      });
      setLogin({ ...login, loading: false });
      if (res.status === 200) {
        
        toast({
          title: "Sent",
          position: "top-right",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        
      } else if (res.status === 404) {
        toast({
          title: "User not found",
          position: "top-right",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Connection Error",
          position: "top-right",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Connection Error",
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
 
  return (
    <Box>
      <Header />
      <Box
        bg="brand.primary"
        shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
        pt={5}
        h={{ base: "100vh", md: "100vh", lg: "100vh" }}
      >
        <Box
          bgImage="url('/images/Group 14.png')"
          bgPosition="center"
          width="100%"
          h={{ base: "none", md: "none", lg: "100%" }}
          overflowY="hidden"
          bgRepeat="no-repeat"
          bgSize={{ base: "cover", md: "contain" }}
        >
          <Center py={{ base: "15%", md: "5%" }}>
            <Box
              pt={{ base: "3", lg: "3" }}
              bg="#FFFFFF"
              width={{ base: "80%", lg: "25%" }}
              borderRadius="2xl"
              filter="drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5))"
            >
              <Text
                fontSize={{ base: "2xl", lg: "3xl" }}
                fontWeight="bold"
                p={5}
                color="#6165D7B2"
              >
                Forgot Password?
              </Text>
              <Text fontSize="md" px={5}>
                We will send a link to create a new password to your registered
                email address.
              </Text>
              <Box px={5} pt={5} pb={10}>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                  })}
                  onSubmit={(values, actions) => {
                    submitLogin(values);
                    actions.resetForm();
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        mt={8}
                        name="email"
                        placeholder="Email"
                        type="email"
                      />

                      <Box
                        as="button"
                        type="submit"
                        bg="brand.primary"
                        color="#00000"
                        borderRadius="2xl"
                        mt={4}
                        width="100%"
                        p={{ base: "2", md: "3", lg: "3" }}
                        fontWeight="bold"
                        fontSize={{ base: "sm", lg: "md" }}
                      >
                        {login.loading?  "Sending...": "RESET MY PASSWORD"}
                        
                      </Box>
                    </form>
                  )}
                </Formik>
                {/* <FormControl pb={10}>
                  <Input
                    mt={8}
                    type="email"
                    name="EMAIL"
                    isRequired
                    variant="filled"
                    id="mce-EMAIL"
                    placeholder="Email"
                  />
                  <Center>
                    <Box
                      as="button"
                      type="submit"
                      bg="brand.primary"
                      color="#00000"
                      borderRadius="2xl"
                      mt={4}
                      width="100%"
                      p={{ base: "2", md: "3", lg: "3" }}
                      fontWeight="bold"
                      fontSize={{ base: "sm", lg: "md" }}
                    ></Box>
                  </Center>
                </FormControl> */}
              </Box>
            </Box>
          </Center>
          <Text pb={7} textAlign="center" fontSize={{ base: "sm" }}>
            &#169; New Horizons Capital . 2022.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
