// routers.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { privateRoutes } from "./privateRoute";
import { publicRoutes } from "./publicRoute";
import RouterGuard from "./routeGuard";

export default function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RouterGuard />}>
          {privateRoutes.map(({ route, page }) => (
            <Route key={route} path={route} element={page} />
          ))}
        </Route>
        {publicRoutes.map(({ route, page }) => (
          <Route key={route} path={route} element={page} />
        ))}
        <Route path="*" />
      </Routes>
    </BrowserRouter>
  );
}
