import React from 'react'
import Dashboard from '../../components/Dashboard/dashboard'
import Layout from '../../components/layout/layout'

const DashboardPage = () => {
  return (
      <Layout>
          <Dashboard/>
    </Layout>
  )
}

export default DashboardPage