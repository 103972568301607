import {
  Box,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useEffect} from "react";
import * as Yup from "yup";
import { usePayoutRequestMutation } from "../../../utils/services/api";
import SelectField from "../../auth/selectField";
import TextField from "../../auth/textField";

const PayoutRequest= ({
  payoutIsOpen,
  payoutOnClose,
  id
}: any) => {
const [payoutRequest,{data,isSuccess,isError,error,isLoading}]= usePayoutRequestMutation()

const toast = useToast();

  useEffect(() => {
    if (isSuccess) {
     toast({
       title: "Request Sent",
       position: "top-right",
       status: "success",
       duration: 9000,
       isClosable: true,
     });
  }
}, [data,isSuccess,toast])
 useEffect(() => {
   if (isError) {
     toast({
       title: (error as any)?.data?.detail,
       position: "top-right",
       status: "error",
       duration: 9000,
       isClosable: true,
     });
   }
 }, [isError,error,toast]);
const validationSchema = Yup.object({
  id: Yup.string().required("id is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(/^\d+$/, "Amount must contain only numbers"),
  wallet_address: Yup.string().required("Wallet Address is required"),
  payment_method: Yup.string().required("Payout Method is required"),
});




  return (
    <Box>
      <Modal
        isOpen={payoutIsOpen}
        onClose={payoutOnClose}
        size={{ base: "xs", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <VStack>
            <ModalHeader
              mt={5}
              fontWeight="extrabold"
              fontSize={{ base: "2xl", md: "2xl" }}
            >
              Request Payout
            </ModalHeader>
          </VStack>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Text color="#D11A2A" fontSize={{ base: "xs", lg: "sm" }} pt="5">
                Payout requests are typically handled within 24-72 hours
              </Text>
            </VStack>
            <Formik
              initialValues={{
                id: id,
                type: "Payout",
                amount: "",
                wallet_address: "",
                payment_method: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                payoutRequest(values);
                actions.resetForm();
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box pb={14}>
                    <FormLabel mt={5}>Fund'r ID</FormLabel>
                    <TextField
                      width={{ base: "", lg: "50%" }}
                      isReadOnly
                      isRequired
                      name="id"
                      variant="filled"
                      type="text"
                      // defaultValue={id}
                    />

                    <FormLabel mt={5}>Request Type</FormLabel>
                    <TextField
                      isReadOnly
                      isRequired
                      name="type"
                      variant="filled"
                      type="text"
                      // defaultValue="Payout"
                    />

                    <FormLabel mt={5}>Amount</FormLabel>
                    <TextField
                      type="text"
                      name="amount"
                      placeholder="e.g 30000"
                      variant="filled"
                    />

                    <FormLabel mt={5}>Payout Method</FormLabel>
                    <SelectField
                      variant="filled"
                      name="payment_method"
                      placeholder="Payout Method"
                      onChange={formik.handleChange}
                      value={formik.values.payment_method}
                    >
                      <option value="BTC">Bitcoin(BTC)</option>
                      <option value="USDT-TRX">Tether (USDT-TRC20)</option>
                    </SelectField>
                    <FormLabel mt={5}>Wallet Address</FormLabel>
                    <TextField
                      type="text"
                      name="wallet_address"
                      variant="filled"
                    />
                    <Box
                      as="button"
                      type="submit"
                      bg="brand.primary"
                      color="#00000"
                      borderRadius="2xl"
                      mt={10}
                      width={{ base: "100%", md: "100%" }}
                      p={{ base: "4", md: "5", lg: "5" }}
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      {isLoading ? "Sending..." : "Request Payout"}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PayoutRequest;
