import { Box, Stack, useToast } from '@chakra-ui/react'
import { Formik } from 'formik';
import { useEffect} from 'react'
import * as Yup from "yup";
import { useChangePasswordMutation } from '../../utils/services/api';
import TextField from '../auth/textField';

const ChangePassword = () => {
  const [changePassword, {isSuccess,isError, error, isLoading}]= useChangePasswordMutation()
const toast = useToast();
  const submitRequest = async ({ values, actions }: any) => {
    changePassword(values)
    
  }
  useEffect(() => {
    if (isSuccess) {
    toast({
      title: "Request Sent",
      position: "top-right",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
   
  }
  }, [isSuccess,toast])
  useEffect(() => {
    if (isError) {
      toast({
        title: (error as any)?.data?.detail,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isError,error,toast])


  const validationSchema = Yup.object({
   
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string()
      .required("password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: Yup.string().when("new_password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
  });
  return (
    <Box>
      <Formik
        initialValues={{
          current_password: "",
          confirm_password: "",
          new_password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          submitRequest({ values, actions });
          actions.resetForm();
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Stack pb={14} gap={4}>
              <TextField
                mt={5}
                name="current_password"
                width={{ base: "100%", lg: "100%" }}
                type="password"
                variant="filled"
                placeholder="Current password"
              />

              <TextField
                name="new_password"
                width={{ base: "100%", lg: "100%" }}
                type="password"
                variant="filled"
                placeholder="New password"
              />

              <TextField
                width={{ base: "100%", lg: "100%" }}
                name="confirm_password"
                type="password"
                variant="filled"
                placeholder="Confirm password"
              />
              <Box
                  as="button"
                  type="submit"
                  bg="brand.primary"
                  p={4}
                  borderRadius="2xl"
                  width={{ base: "100%", lg: "100%" }}
                >
                  {isLoading ? "requesting" : " CHANGE PASSWORD"}
                </Box>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default ChangePassword
 