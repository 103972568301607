import {
  Avatar,
  Box,
  Center,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { BsBriefcaseFill } from "react-icons/bs";
import ChangePassword from "./changePassword";
import { useCurrentUserQuery } from "../../utils/services/api";

// import { CiBadgeDollar } from "react-icons/ci";

const Profile = () => {
  const { data} = useCurrentUserQuery();


  
  return (
    <Box bg="#E5E5E5" h={{ base: "100vh", md: "100%" }}>
      <Box p={{ base: "5", md: "8" }} overflowY="scroll" h="100vh">
        <HStack justifyContent="space-between" pt={5}>
          <Text
            color="#123190BF"
            fontWeight="bold"
            fontSize={{ base: "2xl", lg: "2xl" }}
          >
            Profile
          </Text>
          {/* <Box
            borderRadius="50%"
            bgGradient="linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);"
            p={{ base: "4", lg: "5" }}
            position="relative"
            // shadow="10px 48.5967px 140px rgba(126, 123, 160, 0.2)"
          >
            <Icon
              as={IoIosNotificationsOutline}
              boxSize={{ base: "4", lg: "6" }}
            ></Icon>
            <Icon
              as={GoPrimitiveDot}
              boxSize={{ base: "4", lg: "5" }}
              color="#2DCED6"
              position="absolute"
              top="0.5rem"
              pr={2}
            ></Icon>
          </Box> */}
        </HStack>
        <Box>
          <HStack flexDirection="row" alignItems="stretch" gap={10} pt={5}>
            <Box width={{ base: "100%", lg: "300px" }}>
              <Stack direction="column" spacing={7} mt={4}>
                <Box
                  bgColor="#FFF"
                  shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  borderRadius="2xl"
                  p={5}
                  width={{ base: "", lg: "100%" }}
                >
                  <HStack spacing={5}>
                    <Box borderRadius="full" p={3} bgColor="#ecfefa">
                      <Box>
                        <BsBriefcaseFill color="#45F5CB" size={25} />
                      </Box>
                    </Box>
                    <VStack>
                      <Text fontWeight="extrabold" fontSize="large">
                        {data?.id}
                      </Text>
                      <Text>Fund'r ID</Text>
                    </VStack>
                  </HStack>
                </Box>
                <Box
                  bgColor="#FFF"
                  shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  borderRadius="2xl"
                  p={5}
                  width={{ base: "", lg: "100%" }}
                >
                  <HStack spacing={5}>
                    <Box borderRadius="50%" p={3} bgColor="#ecf6fe">
                      <Box>
                        <BsBriefcaseFill color="#45a1e5" size={22} />
                      </Box>
                    </Box>
                    <VStack>
                      <Text fontWeight="extrabold" fontSize="md">
                        {data?.phase}
                      </Text>
                      <Text textAlign="center">Phase</Text>
                    </VStack>
                  </HStack>
                </Box>
              </Stack>
              <Box display={{ base: "block", lg: "none" }}>
                <Box
                  bgColor="#FFF"
                  shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  borderRadius="2xl"
                  p={{ base: "5", md: "2" }}
                  mt={10}
                >
                  <Text fontWeight="extrabold">Personal Info</Text>
                  <Stack direction="column" mt={5} spacing={4}>
                    <Center>
                      <Avatar name={data?.first_name + " " + data?.last_name} />
                    </Center>
                    <SimpleGrid columns={2} spacing={{ base: "5", md: "1" }}>
                      <Text fontWeight="bold" fontSize="10px">
                        First Name
                      </Text>
                      <Text fontSize="19px">{data?.first_name}</Text>
                      <Text fontWeight="bold">Last Name</Text>
                      <Text fontSize="md">{data?.last_name}</Text>
                      <Text fontWeight="bold">Email</Text>
                      <Text fontSize="md">{data?.email}</Text>
                      <Text fontWeight="bold">Country</Text>
                      <Text fontSize="md">{data?.country}</Text>
                      <Text fontWeight="bold">Mobile</Text>
                      <Text fontSize="md">{data?.phone_no}</Text>
                    </SimpleGrid>
                  </Stack>
                  {/* <Center mt={7}>
                    <Box
                      as="button"
                      bg="brand.primary"
                      p={4}
                      borderRadius="2xl"
                      width="90%"
                    >
                      
                      EDIT PROFILE
                    </Box>
                  </Center> */}
                  <Text fontWeight="extrabold" mt={8}>
                    Change Password
                  </Text>
                  <ChangePassword />
                </Box>
              </Box>
            </Box>
            <Box w="" display={{ base: "none", lg: "block" }}>
              <Box
                bgColor="#FFF"
                shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="2xl"
                p={5}
                width={{ base: "", md: "550px" }}
              >
                <Text fontWeight="extrabold">Personal Info</Text>
                <Stack direction="row" mt={5} spacing={16}>
                  <Avatar name={data?.first_name + " " + data?.last_name} />
                  <SimpleGrid columns={2} gap={8}>
                    <Text fontWeight="bold" fontSize="15px">
                      First Name
                    </Text>
                    <Text fontSize="17px">{data?.first_name}</Text>
                    <Text fontWeight="bold" fontSize="15px">
                      Last Name
                    </Text>
                    <Text fontSize="17px">{data?.last_name}</Text>
                    <Text fontWeight="bold" fontSize="15px">
                      Email
                    </Text>
                    <Text fontSize="17px">{data?.email}</Text>
                    <Text fontWeight="bold" fontSize="15px">
                      Country
                    </Text>
                    <Text fontSize="17px">{data?.country}</Text>
                    <Text fontWeight="bold" fontSize="15px">
                      Mobile
                    </Text>
                    <Text fontSize="17px">{data?.phone_no}</Text>
                  </SimpleGrid>
                </Stack>
                {/* <Center mt={7}>
                  <Box
                    as="button"
                    bg="brand.primary"
                    p={4}
                    borderRadius="2xl"
                    width="70%"
                  >
                    {" "}
                    EDIT PROFILE
                  </Box>
                </Center> */}
                <Text fontWeight="extrabold" mt={8}>
                  Change Password
                </Text>
                <Stack px="60px">
                  <ChangePassword />
                </Stack>
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
