import {
  Box,
  VStack,
  Link,
  HStack,
  Text,
  Image,
  Stack,
  Avatar,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { RoutePaths } from "../../utils/routes/routePaths";
import { useLocation, useNavigate } from "react-router-dom";
// import { RiArrowDropDownLine } from "react-icons/ri";
import { IoLogOut, } from "react-icons/io5";


export default function SideBar({ first_name, last_name, phase }: any) {
  
  const router = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("id")
    localStorage.removeItem("access")
    navigate(RoutePaths.LOGIN);
  }

  return (
    <Box h="100%">
      <Stack>
        <Stack gap="3rem" pt={4}>
          <VStack>
            <Image src="/images/Fund'r Logo.png" width="50%" />
          </VStack>

          <Stack direction="column">
            <VStack gap="1rem">
              <Box
                justifyContent="center"
                width="100%"
                bg={
                  router.pathname === RoutePaths.DASHBOARD
                    ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                    : " "
                }
                p="12px"
                _focus={{ outline: "none" }}
              >
                <HStack justifyContent="center">
                  <AiOutlineMenu
                    color={
                      router.pathname === RoutePaths.DASHBOARD
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                    size={22}
                  />
                  <Link
                    color={
                      router.pathname === RoutePaths.DASHBOARD
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                    style={{ textDecoration: "none" }}
                    fontSize={{ base: "", md: "20px" }}
                    href="/dashboard"
                  >
                    Dashboard
                    <Box as="span" visibility="hidden">
                      outt
                    </Box>
                  </Link>
                </HStack>
              </Box>
              <VStack
                justifyContent="center"
                width="100%"
                bg={
                  router.pathname === RoutePaths.PAYOUT
                    ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                    : " "
                }
                p="12px"
                _focus={{ outline: "none" }}
              >
                <HStack justifyContent="center">
                  <Image
                    src="/images/Withdrawal.png"
                    boxSize={6}
                    color={
                      router.pathname === RoutePaths.PAYOUT
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                  />
                  <Link
                    color={
                      router.pathname === RoutePaths.PAYOUT
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                    style={{ textDecoration: "none" }}
                    fontSize={{ base: "", md: "20px" }}
                    href="/payout"
                  >
                    Payout
                    <Box as="span" visibility="hidden">
                      outttttttt
                    </Box>
                  </Link>
                </HStack>
              </VStack>
              <VStack
                justifyContent="center"
                width="100%"
                bg={
                  router.pathname === RoutePaths.PROFILE
                    ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                    : " "
                }
                p="12px"
                _focus={{ outline: "none" }}
              >
                <HStack justifyContent="center">
                  <IoMdPerson
                    size={22}
                    color={
                      router.pathname === RoutePaths.PROFILE
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                  />
                  <Link
                    color={
                      router.pathname === RoutePaths.PROFILE
                        ? "#6165D7"
                        : "rgba(97, 101, 215, 0.5) "
                    }
                    style={{ textDecoration: "none" }}
                    fontSize={{ base: "", md: "20px" }}
                    href="/profile"
                  >
                    Profile
                    <Box as="span" visibility="hidden">
                      outttttttt
                    </Box>
                  </Link>
                </HStack>
              </VStack>
              <VStack
                justifyContent="center"
                width="100%"
                // bg={
                //   router.pathname === RoutePaths.PROFILE
                //     ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                //     : " "
                // }
                p="12px"
                _focus={{ outline: "none" }}
              >
                <HStack justifyContent="center" position="relative">
                  <FaDiscord size={22} color="rgba(97, 101, 215, 0.5) " />
                  <Link
                    isExternal
                    color="rgba(97, 101, 215, 0.5) "
                    style={{ textDecoration: "none" }}
                    fontSize={{ base: "", md: "20px" }}
                    href="https://discord.gg/gVWnqNVfE"
                  >
                    Community
                    <Box as="span" visibility="hidden">
                      out
                    </Box>
                  </Link>
                  <Text pos="absolute" right={-2} bottom={1} color="#D11A2A">
                    Join
                  </Text>
                </HStack>
              </VStack>
              <Box position="absolute" bottom="1">
                <HStack>
                  <Avatar size="md" name={first_name + " " + last_name} />
                  <Box>
                    <Text fontSize={{ base: "15px", md: "15px" }} fontWeight="bold">
                      {first_name + " " + last_name}
                    </Text>
                    <Text fontSize={{ base: "sm", md: "15px" }}> {phase}</Text>
                  </Box>
                  <IoLogOut
                    cursor="pointer"
                    size={22}
                    color="rgba(97, 101, 215, 0.5) "
                    onClick={logout}
                  />
                </HStack>
              </Box>
            </VStack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
