import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {  ChangePassword, Cyptochil, Login, Page, Payout, PayoutOut, PayoutRequest, ResponseData, ScaleOut, ScaleRequest, UpgradeOut, UpgradeRequest, User, UserOut } from ".";




export const LoginApi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_PROD_ENV === "false"
        ? "http://localhost:8000"
        : process.env.REACT_APP_SERVER_HOST,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      const current_user_token = token?.replace(/[""]+/g, "");
      if (token) {
        headers.set("authorization", `Bearer ${current_user_token}`);
      }

      return headers;
    },
  }),

  tagTypes: ["Login"],
  endpoints: (builder) => ({
    contact: builder.query<void, void>({
      query: () => "/contacts",
    }),
    login: builder.mutation<ResponseData, Login>({
      query: (values: Login) => ({
        url: "/login",
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify(
          `grant_type=&username=${values.email}&password=${values.password}&scope=&client_id=&client_secret=`
        ),
      }),
      // invalidatesTags: ["Login"],
    }),
    currentUser: builder.query<UserOut, void>({
      query: () => "/current_user/me",
      providesTags: ["Login"],
    }),
    signUpUser: builder.mutation<UserOut, User>({
      query: (body: User) => ({
        url: `/signup/?token=${body.token}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Login"],
    }),
    scaleRequest: builder.mutation<ScaleOut, ScaleRequest>({
      query: (body: any) => ({
        url: "/scale",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Login"],
    }),
    upgradeRequest: builder.mutation<UpgradeOut, UpgradeRequest>({
      query: (body: any) => ({
        url: "/upgrade",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Login"],
    }),
    payoutRequest: builder.mutation<PayoutOut, PayoutRequest>({
      query: (body: any) => ({
        url: "/payout",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Login"],
    }),
    payoutHistoryCurrentUser: builder.query<Payout[], Page>({
      query: (page) => `/history?page=${page}&size=5`,
      providesTags: ["Login"],
    }),
    changePassword: builder.mutation<void, ChangePassword>({
      query: (body: ChangePassword) => ({
        url: "/change_password",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Login"],
    }),
    cryptochil: builder.mutation<Cyptochil, any>({
      query: (body: any) => ({
        url: `/cryptochill/${body.endpoint}`,
        method: "POST",
        body: {
          amount: body?.amount,
          currency: body?.currency,
          kind: body?.kind,
          profile_id: body?.profile_id,
          passthrough: { email: body?.email },
        },
      }),
      invalidatesTags: ["Login"],
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpUserMutation,
  useCurrentUserQuery,
  useScaleRequestMutation,
  usePayoutRequestMutation,
  useUpgradeRequestMutation,
  usePayoutHistoryCurrentUserQuery,
  useChangePasswordMutation,
  useCryptochilMutation,
} = LoginApi;