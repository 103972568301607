import { ReactNode } from "react";

import { Avatar, Box, Center,Link, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, HStack, Spinner, Text, useDisclosure, VStack, Image } from "@chakra-ui/react";
import SideBar from "./sidebar";

import DashboardHeaderMobile from "./dashboardHeaderMobile";
import { IoLogOut } from "react-icons/io5";
import { RoutePaths } from "../../utils/routes/routePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
import { useCurrentUserQuery } from "../../utils/services/api";
import { FaDiscord } from "react-icons/fa";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {data} = useCurrentUserQuery();
  
   const router = useLocation();
  const navigate = useNavigate()
const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("access");
  navigate(RoutePaths.LOGIN);
  };

  
 
  return (
    <Box
      display={{ lg: "grid" }}
      gridTemplateColumns={{ lg: "0.8fr 4fr" }}
      minH="100vh"
      maxW={"max(100%,1920px)"}
      mx="auto"
    >
      <Box
        as="aside"
        pt="3rem"
        display={{ base: "none", lg: "block" }}
        borderRight={{ base: "none", lg: "1px solid #d4d3d2" }}
        maxH="100vh"
        overflowY="scroll"
        className="no-scrollbar"
        pb="70px"
      >
        <SideBar
          first_name={data?.first_name}
          last_name={data?.last_name}
          phase={data?.phase}
          id={data?.id}
        />
      </Box>
      <Box
        as="section"
        bgColor="#ffffff"
        maxH="100vh"
        overflowY="scroll"
        className="no-scrollbar"
      >
        <DashboardHeaderMobile openSideNav={onOpen} />

        {children}
        {!children && (
          <Center w="100%" h="100%">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.400"
              size="xl"
            />
          </Center>
        )}
      </Box>

      <Drawer
        size="xs"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        // finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <VStack
            // pos="absolute"
            // top={0}
            // left={0}
            // right={0}
            flexDirection="column"
            p={2}
            pt={10}
            pb={4}
            m={2}
            spacing={3}
            rounded="sm"
            shadow="sm"
          >
            <Box
              justifyContent="center"
              width="100%"
              bg={
                router.pathname === RoutePaths.DASHBOARD
                  ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                  : " "
              }
              p="12px"
              _focus={{ outline: "none" }}
            >
              <HStack justifyContent="center">
                <AiOutlineMenu
                  color={
                    router.pathname === RoutePaths.DASHBOARD
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                  size={22}
                />
                <Link
                  color={
                    router.pathname === RoutePaths.DASHBOARD
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                  style={{ textDecoration: "none" }}
                  fontSize={{ base: "", md: "lg" }}
                  href="/dashboard"
                >
                  Dashboard
                  <Box as="span" visibility="hidden">
                    outt
                  </Box>
                </Link>
              </HStack>
            </Box>
            <VStack
              justifyContent="center"
              width="100%"
              bg={
                router.pathname === RoutePaths.PAYOUT
                  ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                  : " "
              }
              p="12px"
              _focus={{ outline: "none" }}
            >
              <HStack justifyContent="center">
                <Image
                  src="/images/Withdrawal.png"
                  boxSize={6}
                  color={
                    router.pathname === RoutePaths.PAYOUT
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                />
                <Link
                  color={
                    router.pathname === RoutePaths.PAYOUT
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                  style={{ textDecoration: "none" }}
                  fontSize={{ base: "", md: "lg" }}
                  href="/payout"
                >
                  Payout
                  <Box as="span" visibility="hidden">
                    outttttttt
                  </Box>
                </Link>
              </HStack>
            </VStack>
            <VStack
              justifyContent="center"
              width="100%"
              bg={
                router.pathname === RoutePaths.PROFILE
                  ? "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"
                  : " "
              }
              p="12px"
              _focus={{ outline: "none" }}
            >
              <HStack justifyContent="center">
                <IoMdPerson
                  size={22}
                  color={
                    router.pathname === RoutePaths.PROFILE
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                />
                <Link
                  color={
                    router.pathname === RoutePaths.PROFILE
                      ? "#6165D7"
                      : "rgba(97, 101, 215, 0.5) "
                  }
                  style={{ textDecoration: "none" }}
                  fontSize={{ base: "", md: "lg" }}
                  href="/profile"
                >
                  Profile
                  <Box as="span" visibility="hidden">
                    outttttttt
                  </Box>
                </Link>
              </HStack>
            </VStack>
            <VStack
              justifyContent="center"
              width="100%"
              // bg={

              //     "linear-gradient(90deg, rgba(97, 101, 215, 0.2) 0%, rgba(172, 169, 255, 0) 91.25%);"

              // }
              p="12px"
              _focus={{ outline: "none" }}
            >
              <HStack justifyContent="center" position="relative">
                <FaDiscord size={22} color="rgba(97, 101, 215, 0.5) " />
                <Link
                  isExternal
                  color="rgba(97, 101, 215, 0.5) "
                  style={{ textDecoration: "none" }}
                  fontSize={{ base: "", md: "lg" }}
                  href="https://discord.gg/gVWnqNVfE"
                >
                  Community
                  <Box as="span" visibility="hidden">
                    out
                  </Box>
                </Link>
                <Text pos="absolute" right={-2} bottom={1} color="#D11A2A">
                  Join
                </Text>
              </HStack>
            </VStack>
            <Box
              as="button"
              position="absolute"
              bottom="1"
              cursor="pointer"
              onClick={logout}
            >
              <HStack>
                <Avatar
                  size="md"
                  name={data?.first_name + " " + data?.last_name}
                />
                <Box>
                  <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
                    {data?.first_name + " " + data?.last_name}
                  </Text>
                  <Text fontSize={{ base: "sm", md: "sm" }}>
                    {" "}
                    {data?.phase}
                  </Text>
                </Box>
                <IoLogOut size={20} color="rgba(97, 101, 215, 0.5) " />
              </HStack>
            </Box>
          </VStack>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Layout;
