import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useEffect} from "react";
import * as Yup from "yup";
import { useScaleRequestMutation } from "../../../utils/services/api";
import SelectField from "../../auth/selectField";
import TextField from "../../auth/textField";


const Scaling = ({
  scalingIsOpen,
  scalingOnClose,
  capital,id,email
  
}: any) => {
  const [scaleRequest, { isError, isSuccess, isLoading, error}] = useScaleRequestMutation()
const toast = useToast();
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Request Sent",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isSuccess,toast])

  useEffect(() => {
    if (isError) {
      toast({
        title: (error as any)?.data?.detail,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isError,error,toast])
  
  
  const validationSchema = Yup.object({
    id: Yup.string().required("id is required"),
    current_capital: Yup.string().required("Current capital is required"),
    scale_to: Yup.string().required("Next scale is required"),
  });
  // const {loading} = request


  return (
    <Box>
      <Modal
        isOpen={scalingIsOpen}
        onClose={scalingOnClose}
        size={{ base: "xs", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <VStack>
            <ModalHeader
              mt={5}
              fontWeight="extrabold"
              fontSize={{ base: "2xl", md: "2xl" }}
            >
              Request Scaling
            </ModalHeader>
          </VStack>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Text fontSize={{ base: "sm", lg: "md" }}>
                For Fund’r Talents: If you have met the 10% profit target, you
                can request to scale your account capital. Note that after
                scaling, earned profits cannot be withdrawn.
              </Text>
              <Text color="#D11A2A" fontSize={{ base: "xs", lg: "sm" }} pt="5">
                Scale requests are typically handled within 24-48 hours
              </Text>
            </VStack>
            <Formik
              initialValues={{
                id: id,
                type: "Scale",
                current_capital: capital,
                scale_to: "",
                metatrader_password: "",
                mt_login: "",
                mt_server: "",
                analytics: "",
                first_name: "",
                last_name: "",
                email: email,
                country: "",
                phone_no: 0,
                role: "",
                capital: 0,
                status_scale: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions): any => {
                scaleRequest(values);
                // actions.resetForm();
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box pb={14}>
                    <FormLabel mt={5}>Fund'r ID</FormLabel>
                    <TextField
                      width={{ base: "", lg: "50%" }}
                      isReadOnly
                      isRequired
                      name="id"
                      variant="filled"
                      type="text"
                      // defaultValue={id}
                    />
                    <FormLabel mt={5}>Current Capital</FormLabel>
                    <TextField
                      isReadOnly
                      type="text"
                      name="current_capital"
                      // defaultValue={capital}
                    />
                    <FormLabel mt={5}>Scale To</FormLabel>
                    <SelectField name="scale_to" placeholder="Scale to">
                      {capital === "25000" ? (
                        <>
                          <option value="50000">$50,000</option>
                          <option value="100000">$100,000</option>
                          <option value="200000">$200,000</option>
                          <option value="400000">$400,000</option>
                          <option value="1000000">$1,000,000</option>
                          <option value="1500000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "50000" ? (
                        <>
                          <option value="100000">$100,000</option>
                          <option value="200000">$200,000</option>
                          <option value="400000">$400,000</option>
                          <option value="1000000">$1,000,000</option>
                          <option value="1500000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "100000" ? (
                        <>
                          <option value="200000">$200,000</option>
                          <option value="400000">$400,000</option>
                          <option value="1000000">$1,000,000</option>
                          <option value="$1,500,000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "200000" ? (
                        <>
                          <option value="400000">$400,000</option>
                          <option value="1000000">$1,000,000</option>
                          <option value="1500000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "400000" ? (
                        <>
                          <option value="1000000">$1,000,000</option>
                          <option value="1500000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "1000000" ? (
                        <>
                          <option value="1500000">$1,500,000</option>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "1500000" ? (
                        <>
                          <option value="2000000">$2,000,000</option>
                        </>
                      ) : (
                        ""
                      )}
                      {capital === "2000000" ? "" : ""}
                    </SelectField>
                    <Box
                      as="button"
                      type="submit"
                      bg="brand.primary"
                      color="#00000"
                      borderRadius="2xl"
                      mt={10}
                      width={{ base: "100%", md: "100%" }}
                      p={{ base: "4", md: "5", lg: "5" }}
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      {isLoading ? "Sending..." : "Scale "}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
            <FormControl></FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Scaling;
