import { configureStore } from "@reduxjs/toolkit";
// import { LoginApi } from "../components/services/api";
import { setupListeners } from "@reduxjs/toolkit/query";
import { LoginApi } from "./api";
// import authReducer from "../components/services/authSlice";

export const store = configureStore({
  reducer: {
    // cryptochil: cryptochilReducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(LoginApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
