import ForgotPasswordPage from "../../pages/auth/forgotPasswordPage";
import LoginPage from "../../pages/auth/loginPage";
import NewPasswordPage from "../../pages/auth/newPasswordPage";
import SignupPage from "../../pages/auth/signupPage";
import FaqPage from "../../pages/faq/faq";
import HomePage from "../../pages/home/homePage";
import Payment from "../../pages/payment/payment";
import { RoutePaths } from "./routePaths";







export const publicRoutes = [
  {
    route: RoutePaths.BASE_URL,
    page: <HomePage />,
  },
  {
    route: RoutePaths.FAQ,
    page: <FaqPage />,
  },
  {
    route: RoutePaths.PAYMENT,
    page: <Payment/>,
  },
  {
    route: RoutePaths.LOGIN,
    page: <LoginPage/>,
  },
  {
    route: RoutePaths.SIGNUP,
    page: <SignupPage/>,
  },
  {
    route: RoutePaths.FORGOT_PASSWORD,
    page: <ForgotPasswordPage/>,
  },
  {
    route: RoutePaths.NEW_PASSWORD,
    page: <NewPasswordPage/>,
  },
  // {
  //   route: RoutePaths.DASHBOARD,
  //   page: <DashboardPage/>,
  // },
  // {
  //   route: RoutePaths.PROFILE,
  //   page: <ProfilePage/>,
  // },
  // {
  //   route: RoutePaths.PAYOUT,
  //   page: <PayoutPage/>,
  // },
];