/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {  Outlet, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../utils/routes/routePaths";


const RouterGuard = () => {
  const navigate = useNavigate();
const token = localStorage.getItem("token");
const current_user_token = token?.replace(/[""]+/g, "");
  const access = localStorage.getItem("access");
  const baseUrl =
    process.env.REACT_APP_PROD_ENV === "false"
      ? "http://localhost:8000"
      : process.env.REACT_APP_SERVER_HOST;


  useEffect(() => {
    fetch(`${baseUrl}/token/?token=${current_user_token}`)
      .then((response) => {
        if (response.status === 200) {
          
        } else {

        localStorage.removeItem("access");
        localStorage.removeItem("token");
           navigate(RoutePaths.LOGIN);
        }
      })
      .then((data) => {
        console.log(data);
        if (!access) {
          navigate(RoutePaths.LOGIN);
        }
      })
      .catch((error) => {
        localStorage.removeItem("access");
        localStorage.removeItem("token");
         navigate(RoutePaths.LOGIN);
      });
  }, []);


  return <Outlet />;
};

export default RouterGuard;
