import {
  Box,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useEffect} from "react";
import * as Yup from "yup";
import { useUpgradeRequestMutation } from "../../../utils/services/api";
import SelectField from "../../auth/selectField";
import TextField from "../../auth/textField";

const Upgrade = ({
  upgradeIsOpen,
  upgradeOnClose,
  phase,id, email
}: any) => {
   const [upgradeRequest, { isError, isSuccess, isLoading, error }] =
     useUpgradeRequestMutation();
   const toast = useToast();
   useEffect(() => {
     if (isSuccess) {
       toast({
         title: "Request Sent",
         position: "top-right",
         status: "success",
         duration: 9000,
         isClosable: true,
       });
     }
   }, [isSuccess,toast]);

   useEffect(() => {
     if (isError) {
       toast({
         title: (error as any)?.data?.detail,
         position: "top-right",
         status: "error",
         duration: 9000,
         isClosable: true,
       });
     }
   }, [isError,error,toast]);

   const validationSchema = Yup.object({
     id: Yup.string().required("id is required"),
     current_phase: Yup.string().required("Current phase is required"),
     upgrade_to: Yup.string().required("Next phase is required"),
   });

  return (
    <Box>
      <Modal
        isOpen={upgradeIsOpen}
        onClose={upgradeOnClose}
        size={{ base: "xs", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <VStack>
            <ModalHeader
              mt={5}
              fontWeight="extrabold"
              fontSize={{ base: "2xl", md: "2xl" }}
            >
              Request Upgrade
            </ModalHeader>
          </VStack>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Text fontSize={{ base: "sm", lg: "md" }}>
                For Evaluation Traders: If you have met the profit target
                without violating any rule or hitting the drawdown limit, you
                can request for an upgrade to the next phase.
              </Text>
              <Text color="#D11A2A" fontSize={{ base: "xs", lg: "sm" }} pt="5">
                Upgrade requests are typically handled within 24-48 hours
              </Text>
            </VStack>

            <Formik
              initialValues={{
                id: id,
                type: "Upgrade",
                current_phase: phase,
                upgrade_to: "",
                first_name: "",
                last_name: "",
                email: email,
                country: "",
                phone_no: 0,
                role: "",
                capital: "",
                status_upgrade: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                upgradeRequest(values);
                actions.resetForm();
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box pb={14}>
                    <FormLabel mt={5}>Fund'r ID</FormLabel>
                    <TextField
                      width={{ base: "", lg: "50%" }}
                      isReadOnly
                      isRequired
                      name="id"
                      variant="filled"
                      type="text"
                      // defaultValue={id}
                    />

                    <FormLabel mt={5}>Request Type</FormLabel>
                    <TextField
                      isReadOnly
                      isRequired
                      name="type"
                      variant="filled"
                      type="text"
                      // defaultValue="Upgrade"
                    />

                    <FormLabel mt={5}>Current Phase</FormLabel>
                    <TextField
                      isReadOnly
                      type="text"
                      name="current_phase"
                      isRequired
                      variant="filled"
                      // defaultValue={phase}
                    />

                    <FormLabel mt={5}>Upgrade To</FormLabel>
                    <SelectField
                      variant="filled"
                      name="upgrade_to"
                      placeholder="Upgrade to"
                      onChange={formik.handleChange}
                      value={formik.values.upgrade_to}
                    >
                      {phase === "Evaluation" ? (
                        <>
                          <option value="Verification">Verification</option>
                          <option value="Fundr Talent">Fundr Talent</option>
                        </>
                      ) : (
                        ""
                      )}
                      {phase === "Verification" ? (
                        <>
                          <option value="Fundr Talent">Fundr Talent</option>
                        </>
                      ) : (
                        ""
                      )}
                      {phase === "Fundr Talent" ? "" : ""}
                    </SelectField>
                    <Box
                      as="button"
                      type="submit"
                      bg="brand.primary"
                      color="#00000"
                      borderRadius="2xl"
                      mt={10}
                      width={{ base: "100%", md: "100%" }}
                      p={{ base: "4", md: "5", lg: "5" }}
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      {isLoading ? "sending" : "Upgrade Account"}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Upgrade;
