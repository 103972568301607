import React from 'react'
import Login from '../../components/auth/login'


const LoginPage = () => {
  return (
    <Login/>
  )
}

export default LoginPage