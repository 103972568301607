
import Layout from '../../components/layout/layout'
import Payout from '../../components/payout/payout'

const PayoutPage = () => {
  return (
    <Layout>
      <Payout />
    </Layout>
  )
}

export default PayoutPage