/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, HStack, Image, Link, SimpleGrid, Stack, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { BsBriefcaseFill } from 'react-icons/bs';
import { useEffect } from "react";
import { CiBadgeDollar } from "react-icons/ci";
// import { useEffect } from 'react';
import Upgrade from '../modals/requests/upgrade';
import Scaling from '../modals/requests/scaling';
// import { ExternalLinkIcon } from "@chakra-ui/icons";
import PayoutRequest from '../modals/requests/payout';
import { useCurrentUserQuery } from '../../utils/services/api';
// import { getNextTargetDates, isTargetDate } from './payoutCountDown';
import { getDaysUntilNextActiveDay, isPayoutButtonActive } from './payoutButton';
import { RoutePaths } from '../../utils/routes/routePaths';

const Dashboard = () => {
 
  const { data } = useCurrentUserQuery();
  const toast = useToast();
  
  const baseUrl = process.env.REACT_APP_PROD_ENV === "false"? "http://localhost:8000": process.env.REACT_APP_SERVER_HOST

 useEffect(() => {
   if (data) {
     const user_id = data?.id; // Stringify the user ID if needed
     const socket = new WebSocket(`wss://${baseUrl}/ws/${user_id}`);


     socket.onopen = () => {
        
       console.log("WebSocket connection established");
     };

     socket.onmessage = (event) => {
       const message = event.data;
       toast({
         title: message,
         position: "top",
         status: "info",
         duration: 18000,
         isClosable: true,
       });
      //  console.log("Received WebSocket message:", message);

       // Handle the received message according to your requirements
     };

     socket.onclose = () => {
       console.log("WebSocket connection closed");
     };

     return () => {
       socket.close();
     };
   }
 }, [data,toast]);
  
const handleClick = (e:any) => {
  e.preventDefault();
  const url = "https://trade.metatrader5.com/terminal"; // Replace with your external link
  window.open(url, "_blank"); // Open the link in a new tab
};


   const {
     isOpen: upgradeIsOpen,
     onOpen: upgradeOnOpen,
     onClose: upgradeOnClose,
   } = useDisclosure();
   const {
     isOpen: scalingIsOpen,
     onOpen: scalingOnOpen,
     onClose: scalingOnClose,
   } = useDisclosure();
   const {
     isOpen: payoutIsOpen,
     onOpen: payoutOnOpen,
     onClose: payoutOnClose,
   } = useDisclosure();
 
  
  

  const buttons = [
    {
      name: "REQUEST UPGRADE",
      image: "/images/carbon_intent-request-upgrade.png",
    },
    {
      name: "REQUEST SCALE",
      image: "/images/game-icons_upgrade.png",
      desc: "Only available to Fund'r Talents",
    },
    {
      name: "REQUEST PAYOUT",
      image: "/images/bx_money-withdraw.png",
      desc: `Time till next payout window: ${getDaysUntilNextActiveDay()} days `,
    },
    {
      name: "WEB TRADER",
      image: "/images/material-symbols_currency-exchange-rounded.png",
      desc: "For IOS users",
    },
  ];
  
  return (
    <Box>
      <Upgrade
        upgradeIsOpen={upgradeIsOpen}
        upgradeOnClose={upgradeOnClose}
        phase={data?.phase}
        id={data?.id}
        email={data?.email}
      />
      <Scaling
        scalingIsOpen={scalingIsOpen}
        scalingOnClose={scalingOnClose}
        capital={data?.capital}
        id={data?.id}
        email={data?.email}
      />
      <PayoutRequest
        payoutIsOpen={payoutIsOpen}
        payoutOnClose={payoutOnClose}
        id={data?.id}
      />
      <Box
        p={{ base: "5", md: "10" }}
        bg="#E5E5E5"
        h="100vh"
        overflowY="scroll"
      >
        <HStack justifyContent="space-between">
          <Text
            color="#123190BF"
            fontWeight="bold"
            fontSize={{ base: "2xl", lg: "2xl" }}
          >
            Hello,{" "}
            <Box as="span" fontWeight="normal">
              {data?.first_name}
            </Box>
          </Text>
          {/* <Box
            borderRadius="50%"
            bgGradient="linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);"
            p={{ base: "4", lg: "5" }}
            position="relative"
            // shadow="10px 48.5967px 140px rgba(126, 123, 160, 0.2)"
          >
            <Icon
              as={IoIosNotificationsOutline}
              boxSize={{ base: "4", lg: "6" }}
            ></Icon>
            <Icon
              as={GoPrimitiveDot}
              boxSize={{ base: "4", lg: "5" }}
              color="#2DCED6"
              position="absolute"
              top="0.5rem"
              pr={2}
            ></Icon>
          </Box> */}
        </HStack>
        <HStack pt={10}>
          <Box w={{ base: "100%", lg: "1200px" }}>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "6", md: "7" }}
              w={{ base: "", md: "100%" }}
            >
              <Box
                w={{ base: "", md: "250px" }}
                bgColor="#FFF"
                shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="2xl"
                p={5}
              >
                <HStack
                  spacing={{ base: "6", md: "5" }}
                  justifyContent={{ base: "flex-start", md: "space-evenly" }}
                >
                  <Box
                    borderRadius="full"
                    p={3}
                    bgColor="#ecfefa"
                    w={{ base: "", md: "50px" }}
                  >
                    <Box>
                      <BsBriefcaseFill color="#45F5CB" size={25} />
                    </Box>
                  </Box>
                  <VStack>
                    <Text
                      fontWeight="extrabold"
                      fontSize={{ base: "larger", md: "20px" }}
                    >
                      {data?.id}
                    </Text>
                    <Text fontSize={{ base: "md", md: "17px" }}>Fund'r ID</Text>
                  </VStack>
                </HStack>
              </Box>
              <Box
                w={{ base: "", md: "250px" }}
                bgColor="#FFF"
                shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="2xl"
                p={5}
              >
                <HStack
                  spacing={5}
                  justifyContent={{ base: "", md: "space-evenly" }}
                >
                  <Box
                    borderRadius="50%"
                    p={3}
                    bgColor="#eff0fb"
                    w={{ base: "", md: "50px" }}
                  >
                    <Box>
                      <CiBadgeDollar color="#6165d7" size={25} />
                    </Box>
                  </Box>
                  <VStack>
                    <Text
                      fontWeight="extrabold"
                      fontSize={{ base: "larger", md: "20px" }}
                    >
                      {data?.capital}
                    </Text>
                    <Text
                      fontSize={{ base: "md", md: "17px" }}
                      w={{ base: "", md: "100%" }}
                    >
                      Account Capital
                    </Text>
                  </VStack>
                </HStack>
              </Box>
              <Box
                w={{ base: "", md: "250px" }}
                bgColor="#FFF"
                shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="2xl"
                p={5}
              >
                <HStack
                  spacing={5}
                  justifyContent={{ base: "", md: "space-evenly" }}
                >
                  <Box
                    borderRadius="50%"
                    p={3}
                    bgColor="#ecf6fe"
                    w={{ base: "", md: "50px" }}
                  >
                    <Box>
                      <BsBriefcaseFill color="#45a1e5" size={25} />
                    </Box>
                  </Box>
                  <VStack>
                    <Text
                      fontWeight="extrabold"
                      fontSize={{ base: "larger", md: "20px" }}
                    >
                      {data?.phase}
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize={{ base: "md", md: "17px" }}
                    >
                      Phase
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            </Stack>
            <Stack
              w={{ base: "100%", md: "800px" }}
              direction={{ base: "column", md: "row" }}
              pt={12}
              spacing={{ base: "10", lg: "18" }}
              justifyContent="space-between"
            >
              <Box
                p={5}
                w={{ base: "100%", md: "50%" }}
                bg="#FFFFFF"
                borderRadius="2xl"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              >
                <SimpleGrid columns={2} gap={3}>
                  <Text fontSize={{ base: "16px", md: "16px" }}>Type</Text>
                  <Text
                    fontWeight="extrabold"
                    fontSize={{ base: "15px", md: "18px" }}
                  >
                    {data?.type_meta}
                  </Text>
                  <Text pt={5} fontSize="16px">Account ID</Text>
                  <Text
                    pt={5}
                    fontWeight="extrabold"
                    fontSize={{ base: "15px", md: "18px" }}
                  >
                    {data?.account_id_meta}
                  </Text>
                  <Text pt={5}>Password</Text>
                  <Text
                    pt={5}
                    fontWeight="extrabold"
                    fontSize={{ base: "15px", md: "18px" }}
                  >
                    {data?.metatrader_password}
                  </Text>
                  <Text pt={5}>Server</Text>
                  <Text
                    pt={5}
                    fontWeight="extrabold"
                    fontSize={{ base: "15px", md: "18px" }}
                  >
                    {data?.mt_server}
                  </Text>
                  <Text pt={5}>Analytics Link</Text>
                  <Text
                    pt={5}
                    fontWeight="extrabold"
                    fontSize={{ base: "15px", md: "18px" }}
                  >
                    <Link href={data?.analytics} isExternal>
                      {data?.analytics}
                    </Link>
                  </Text>
                </SimpleGrid>
              </Box>
              <Center>
                <Box
                  bg="#605BFF"
                  maxH="60px"
                  borderRadius="2xl"
                  p={5}
                  color="#FFFFFF"
                >
                  <Link href={RoutePaths.FAQ}>Read the Rules</Link>
                </Box>
              </Center>
            </Stack>
            <>
              <SimpleGrid
                columns={2}
                spacing={{ base: "5" }}
                pt={{ base: "", md: "4" }}
              >
                {buttons?.map((item: any, index: number) => (
                  <Box key={index}>
                    <Box
                      mt={8}
                     
                      as="button"
                      onClick={(e: any) => {
                        if (item.name === "REQUEST UPGRADE") {
                          upgradeOnOpen();
                        } else if (item.name === "REQUEST SCALE") {
                          scalingOnOpen();
                        } else if (item.name === "REQUEST PAYOUT") {
                          payoutOnOpen();
                        } else if (item.name === "WEB TRADER") {
                          handleClick(e);
                        }
                      }}
                      cursor="pointer"
                      bg={
                        (item.name === "REQUEST PAYOUT" &&
                          !isPayoutButtonActive()) ||
                        (item.name === "REQUEST SCALE" &&
                          data?.phase !== "Fundr Talent") ||
                        (item.name === "REQUEST UPGRADE" &&
                          data?.status !== "Completed")
                          ? "gray.300"
                          : "brand.primary"
                      }
                      p={{ base: "5", lg: "7" }}
                      disabled={
                        (item.name === "REQUEST PAYOUT" &&
                          !isPayoutButtonActive()) ||
                        (item.name === "REQUEST SCALE" &&
                          data?.phase !== "Fundr Talent") ||
                        (item.name === "REQUEST UPGRADE" &&
                          data?.status !== "Completed")
                      }
                      width={{ base: "150px", lg: "300px" }}
                      shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                      borderRadius="2xl"
                    >
                      <HStack justifyContent={{md:"center"}} >
                        <Image
                          src={item.image}
                          boxSize={{ base: "5", lg: "8" }}
                        />
                        <Text
                          
                          fontSize={{ base: "15px", lg: "md" }}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Text>
                      </HStack>
                    </Box>
                    <Text
                      p={{ base: "2" }}
                      width={{ base: "100%", lg: "58%" }}
                      mt={3}
                      fontSize={{ base: "sm", lg: "sm" }}
                      textAlign="start"
                      color="#D11A2A"
                    >
                      {item.name === "REQUEST SCALE" &&
                      data?.phase === "Fundr Talent"
                        ? ""
                        : item.desc}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
              {/* <Box pt={5} display={{ base: "block", lg: "none" }}>
                <Box
                  borderRadius="2xl"
                  bg="#FFFFFF"
                  p={5}
                  filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                >
                  <Text fontWeight="extrabold">Upcoming Market News</Text>
                </Box>
              </Box> */}
            </>
          </Box>
          {/* <Box w="35%" display={{ base: "none", lg: "block" }}>
            <Box
              width="80%"
              borderRadius="2xl"
              bg="#FFFFFF"
              p={5}
              h="60%"
              filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            >
              <Text fontWeight="extrabold">Upcoming Market News</Text>
            </Box>
          </Box> */}
        </HStack>
      </Box>
    </Box>
  );
}

export default Dashboard