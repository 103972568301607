
import DashboardPage from "../../pages/dashboard/dashboardPage";
import PayoutPage from "../../pages/payout/payoutPage";
import ProfilePage from "../../pages/Profile/profilePage";
import { RoutePaths } from "./routePaths";







export const privateRoutes = [

  {
    route: RoutePaths.DASHBOARD,
    page: <DashboardPage/>,
  },
  {
    route: RoutePaths.PROFILE,
    page: <ProfilePage/>,
  },
  {
    route: RoutePaths.PAYOUT,
    page: <PayoutPage/>,
  },
];