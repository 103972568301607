import { Box, Container, SimpleGrid,  Text } from '@chakra-ui/react'

const CompletePayment = () => {
  return (
    <Box>
      <Box backgroundColor="#E5E5E5">
        <Container>
          <Box pt={10} mb={3}>
            <Text color="#123190BF" fontSize="2xl" fontWeight="bold">
              Payment
            </Text>
          </Box>
          <Box bg="#FFFFFF" p={5} borderRadius="2xl" pt={5} ml={4}>
            <Text pt={5} pl={4} fontSize="lg">
              Tether (Tron, TRX20) payment address
            </Text>
            <Text pt={20} pl={4} pb={10} fontSize="lg">
              Amount to send
            </Text>
          </Box>
          <Box pt={14} mb={5}>
            <Text color="#123190BF" fontSize="2xl" fontWeight="bold">
              Invoice
            </Text>
          </Box>
          <Box pt={5} ml={4}>
            <SimpleGrid columns={{ base: 1, md: 2 }}>
              <Text color="#030229">Description</Text>
              <Text fontWeight="extrabold">
                Purchase Fund’r $25,000 account. (USDT-TRC20)
              </Text>
              <Text color="#030229" mt={2}>
                Payment Requested
              </Text>
              <Text fontWeight="extrabold" mt={2}>
                249.782261 USDT-TRX (250.00 USD)
              </Text>
              <Text color="#030229" mt={2}>
                Amount Paid
              </Text>
              <Text fontWeight="extrabold" mt={2}>
                xx USDT-TRX
              </Text>
              <Text color="#030229" mt={2}>
                Invoice ID
              </Text>
              <Text fontWeight="extrabold" mt={2}>
                8A82A1C3394649398498401B106F7C1B
              </Text>
              <Text color="#030229" mt={2}>
                Payment Address
              </Text>
              <Text fontWeight="extrabold" mt={2}>
                8A82A1C3394649398498401B106F7C1B
              </Text>
              <Text color="#030229" mt={2}>
                Email Address
              </Text>
              <Text fontWeight="extrabold" mt={2}>
                8A82A1C3394649398498401B106F7C1B
              </Text>
            </SimpleGrid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default CompletePayment