import React from 'react'
import Faq from '../../components/faq/faq';
import HeaderLogin from '../../components/utils/headerLogin';
import FaqHeader from '../../components/home/faqHeader';


const FaqPage = () => {
const access = localStorage.getItem("access");

  return (
    <>
      {access ? <HeaderLogin /> : <FaqHeader />}
      <Faq />
    </>
  );
}

export default FaqPage; 