// eslint-disable-next-line
import {
  Box,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { useCryptochilMutation } from "../../../utils/services/api";
import SelectField from "../../auth/selectField";
import TextField from "../../auth/textField";

const PurchaseAccount = ({ isOpen, onClose, sizeValue, feeValue }: any) => {
  const toast = useToast();
  const [cryptochil, { data, isLoading, isError, error, isSuccess }] =
    useCryptochilMutation();
  const validationSchema = Yup.object({
    fee: Yup.string().required("Size is required"),
    email: Yup.string().required("Email is required"),
    amount: Yup.string().required("Fee is required"),
    kind: Yup.string().required("Method is required"),
  });
  const handleSubmit = (values: any) => {
    cryptochil(values);
  };
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Invoice Generated. SignUp Link Sent To Registered Email.",
        position: "top-right",
        status: "success",
        duration: 12000,
        isClosable: true,
      });
      
      
    } 
  }, [data,isSuccess,toast]);

  useEffect(() => {
    if (isError) {
      toast({
        title: (error as any)?.data?.detail,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [data, isError, error, toast]);

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "xs", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            mt={5}
            fontWeight="extrabold"
            fontSize={{ base: "2xl", md: "2xl" }}
          >
            Purchase a New Account
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                amount: "1",
                currency: "USDT",
                kind: "",
                profile_id: "4f132cb5-11f6-409f-9e5a-8d00f65702e9",
                email: "",
                endpoint: "invoices",
                fee: sizeValue,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.resetForm();
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box pb={14}>
                    <FormLabel mt={5}>Account Size</FormLabel>
                    <TextField
                      isReadOnly
                      name="fee"
                      variant="filled"
                      type="text"
                      defaultValue={sizeValue}
                    />

                    <FormLabel mt={5}>Email address</FormLabel>
                    <TextField name="email" variant="filled" type="email" />

                    <FormLabel mt={5}>Fee</FormLabel>
                    <TextField
                      type="text"
                      isReadOnly
                      name="amount"
                      placeholder="Fee"
                      variant="filled"
                      defaultValue={feeValue}
                    />

                    <FormLabel mt={5}>Payment Method</FormLabel>
                    <SelectField
                      variant="filled"
                      name="kind"
                      onChange={formik.handleChange}
                      value={formik.values.kind}
                    >
                      <option value="">Select Method</option>
                      <option value="BTC">Bitcoin(BTC)</option>
                      <option value="USDT-TRX">Tether (USDT-TRC20)</option>
                    </SelectField>
                    <Box
                      as="button"
                      type="submit"
                      bg="brand.primary"
                      color="#00000"
                      borderRadius="2xl"
                      mt={10}
                      width={{ base: "100%", md: "100%" }}
                      p={{ base: "4", md: "5", lg: "5" }}
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      {isLoading ? "Generating Invoice" : "Proceed To Payment"}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PurchaseAccount;
