import { Box, HStack, Stack, Text, useDisclosure } from "@chakra-ui/react";
// import { GoPrimitiveDot } from "react-icons/go";
// import { IoIosNotificationsOutline } from "react-icons/io";
import { getDaysUntilNextActiveDay, isPayoutButtonActive } from "../Dashboard/payoutButton";
import PayoutRequest from "../modals/requests/payout";
import History from "./history";

// import Milestone from "./milestone";


const Payout = () => {
  
 const id = localStorage.getItem("id");
  const {
    isOpen: payoutIsOpen,
    onOpen: payoutOnOpen,
    onClose: payoutOnClose,
  } = useDisclosure();


  return (
    <Box
      p={{ base: "3", lg: "8" }}
      bg="#E5E5E5"
      h={{ base: "100vh", md: "100%" }}
      overflowY="scroll"
    >
      <PayoutRequest
        payoutIsOpen={payoutIsOpen}
        payoutOnClose={payoutOnClose}
        id={id}
      />
      <HStack justifyContent="space-between" p={5}>
        <Text
          color="#123190BF"
          fontWeight="bold"
          fontSize={{ base: "3xl", lg: "2xl" }}
        >
          Payouts
        </Text>
        {/* <Box
          borderRadius="50%"
          bgGradient="linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);"
          p={{ base: "4", lg: "5" }}
          position="relative"
          // shadow="10px 48.5967px 140px rgba(126, 123, 160, 0.2)"
        >
          <Icon
            as={IoIosNotificationsOutline}
            boxSize={{ base: "4", lg: "6" }}
          ></Icon>
          <Icon
            as={GoPrimitiveDot}
            boxSize={{ base: "4", lg: "5" }}
            color="#2DCED6"
            position="absolute"
            top="0.5rem"
            pr={2}
          ></Icon>
        </Box> */}
      </HStack>
      <Box p="5">
        <Text color="#030229" fontSize={{ base: "16px", lg: "18px" }}>
          Always Pay The Trader <br /> Withdraw your earned profits, you deserve
          it.
        </Text>
      </Box>
      <HStack flexDirection="row" alignItems="stretch" gap={20} p="5">
        <Box width={{ base: "100%", lg: "90%" }}>
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "2", lg: "7" }}
          >
            <Box justifyContent={{ base: "center" }}>
              <Box
                as="button"
                p={{ base: "7", lg: "8" }}
                width={{ base: "250px" }}
                textAlign="center"
                fontWeight="bold"
                fontSize={{ base: "lg", lg: "lg" }}
                bg={
                  !isPayoutButtonActive()
                    ? "gray.300"
                    : "linear-gradient(89.93deg, rgba(69, 245, 203, 0.31) 0.06%, rgba(103, 172, 236, 0.53) 82.03%)"
                }
                borderRadius="2xl"
                shadow=" 0px 4px 10px rgba(0, 0, 0, 0.25)"
                cursor="pointer"
                disabled={!isPayoutButtonActive()}
                onClick={payoutOnOpen}
              >
                REQUEST PAYOUT
              </Box>
              <Text
                color="#D11A2A"
                fontSize="15px"
                mt={3}
                width={{ base: "100%" }}
              >
                Time till next payout window: {getDaysUntilNextActiveDay()}
                days
              </Text>
            </Box>
            <Text
              fontSize={{ base: "16px", lg: "18px" }}
              width={{ lg: "50%" }}
              pt={{ base: "5", lg: "5" }}
            >
              Payout requests are made on a rolling basis, every on the 13th and
              27th of each month.
            </Text>
          </Stack>
          {/* <Box display={{ base: "block", lg: "none" }} pt={5}>
            <Milestone />
          </Box> */}
          <Box mt={10}>
            <History />
          </Box>
        </Box>
        {/* <Box w="40%" display={{ base: "none", lg: "block" }}>
          <Milestone />
        </Box> */}
      </HStack>
    </Box>
  );
};

export default Payout;
