import { Box, Center, HStack, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { HiOutlineXMark } from "react-icons/hi2";
import { IoIosArrowBack, IoIosArrowForward, IoMdCheckmark } from 'react-icons/io';
import { usePayoutHistoryCurrentUserQuery } from '../../utils/services/api';


const History = () => {
  const [userDetails, setUserDetails] = useState<any>({});
  const [page, setPage] = useState<any>(1)
  const {data, isSuccess, isError} = usePayoutHistoryCurrentUserQuery(page)
  

  const increment = () => {
    setPage(page + 1)
  }
  const decrement = () => {
   if (page > 1) setPage(page - 1);
  }

  
  useEffect(() => {
    if (isSuccess) {
      setUserDetails(data)
    }
  },[data,isSuccess])
 

  return (
    <Box>
      <Text
        fontWeight="bold"
        color="#123190BF"
        fontSize={{ base: "2xl", lg: "2xl" }}
      >
        History
      </Text>
      <Box  pt={5} overflowX={{base:"scroll"}}>
        <Table variant="simple" size="lg">
          <TableContainer>
            <TableCaption>
              <Center>
                <HStack>
                  {isSuccess ? (
                    <>
                      <IoIosArrowBack
                        size={20}
                        onClick={decrement}
                        cursor="pointer"
                      />
                      <Text>
                        Showing {userDetails?.page} - {userDetails?.size} of
                        {userDetails?.total}
                      </Text>
                      <IoIosArrowForward
                        size={20}
                        cursor="pointer"
                        onClick={increment}
                      />
                    </>
                  ) : (
                    "No Data Found"
                  )}
                </HStack>
              </Center>
            </TableCaption>
            <Thead>
              <Tr
                bg="#FFF"
                borderRadius="2xl"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              >
                <Th>
                  <Text fontSize="x-small">S/N</Text>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize="x-small">Status</Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize="x-small">Method</Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize="x-small">Amount</Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize="x-small">Profit Share</Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text fontSize="x-small">Wallet Address</Text>
                  </Box>
                </Th>
              </Tr>
            </Thead>
            {userDetails && userDetails?.items && !isError
              ? userDetails?.items.map((item: any, index: number) => (
                  <Tbody
                    key={index}
                    fontSize="sm"
                    bg="#FFF"
                    borderRadius="2xl"
                    filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                  >
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>
                        {item.status === "Pending" && (
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xs"
                          />
                        )}
                        {item.status === "Completed" && (
                          <Box bg="#00B42A" borderRadius="50%" boxSize="20px">
                            <Center>
                              <IoMdCheckmark size={20} color="#FFF" />
                            </Center>
                          </Box>
                        )}
                        {item.status === "Rejected" && (
                          <Box bg="#F53F3F" borderRadius="50%" boxSize="25px">
                            <Center>
                              <HiOutlineXMark size={25} color="#FFF" />
                            </Center>
                          </Box>
                        )}
                      </Td>
                      <Td>{item.payment_method}</Td>
                      <Td>{item.amount}</Td>
                      <Td>{item.payable_amount}</Td>
                      <Td>{item.wallet_address}</Td>
                    </Tr>
                  </Tbody>
                ))
              : "No Data Found"}
          </TableContainer>
        </Table>
      </Box>
    </Box>
  );
}

export default History