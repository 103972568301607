
import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Link,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { IoMenuOutline, IoPerson } from "react-icons/io5"
import { Link as Scroll } from "react-scroll";
import { useCurrentUserQuery } from "../../utils/services/api";
import { RoutePaths } from "../../utils/routes/routePaths";

const FaqHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const btnRef = React.useRef();
const { data } = useCurrentUserQuery();
  return (
    <Box>
      <Box>
        <Box bg="brand.primary" w="100%" p={5}>
          <HStack justifyContent="space-between" px="2%">
            <Link href="/">
              <Image
                src="/images/Fund'r Logo.png"
                boxSize={{ base: "", md: "10", lg: "10" }}
                width={{ base: "45%", md: "70%", lg: "100%" }}
              />
            </Link>
            <Box display={{ base: "block", lg: "none" }}>
              <HStack gap={2}>
                {/* <IoPerson size={20} /> */}
                <Box onClick={onOpen}>
                  <IoMenuOutline size={32} />
                </Box>
              </HStack>
              <Drawer
                size="xs"
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                // finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <VStack
                    // pos="absolute"
                    // top={0}
                    // left={0}
                    // right={0}
                    flexDirection="column"
                    p={2}
                    pt={10}
                    pb={4}
                    m={2}
                    spacing={3}
                    rounded="sm"
                    shadow="sm"
                  >
                    <Link
                      href="/"
                    >
                      <Button w="full" variant="ghost">
                        How It Works
                      </Button>
                    </Link>
                    <Link
                      href="/"
                    >
                      <Button w="full" variant="ghost">
                        FAQ
                      </Button>
                    </Link>
                    <Scroll
                      activeClass="active"
                      to="FAQ"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {/* <Button w="full" variant="ghost">
                        Partners
                      </Button> */}
                    </Scroll>
                    {/* <Button w="full" variant="ghost">
                      Leaderboard
                    </Button> */}
                    <Box p={4} border="1px" borderRadius="2xl" cursor="pointer">
                      {data?.id ? (
                        <>
                          <HStack spacing={5}>
                            <IoPerson />
                            <Text fontSize="large" fontWeight="extrabold">
                              <Link href={RoutePaths.DASHBOARD}>
                                Back to Dashboard
                              </Link>
                            </Text>
                          </HStack>
                        </>
                      ) : (
                        <>
                          <HStack spacing={5}>
                            <IoPerson />
                            <Text fontSize="large" fontWeight="extrabold">
                              <Link href={RoutePaths.LOGIN}>Login</Link>
                            </Text>
                          </HStack>
                        </>
                      )}
                    </Box>
                  </VStack>
                </DrawerContent>
              </Drawer>
            </Box>
            <Box pl="8rem" display={{ base: "none", lg: "block" }}>
              <HStack spacing={10}>
                <Link
                  href="/"
                >
                  <Text
                    cursor="pointer"
                    fontSize="large"
                    fontWeight="extrabold"
                  >
                    How It Works
                  </Text>
                </Link>
                <Link
                 href="/"
                >
                  <Text
                    cursor="pointer"
                    fontSize="large"
                    fontWeight="extrabold"
                  >
                    FAQ
                  </Text>
                </Link>
                <Link
                  href="/"
                >
                  {/* <Text
                    cursor="pointer"
                    fontSize="large"
                    fontWeight="extrabold"
                  >
                    Partners
                  </Text> */}
                </Link>
                {/* <Text cursor="pointer" fontSize="large" fontWeight="extrabold">
                  Leaderboard
                </Text> */}
              </HStack>
            </Box>
            <Box pl="14rem" display={{ base: "none", lg: "block" }}>
              <Box>
                <Box p={4} border="1px" borderRadius="2xl" cursor="pointer">
                  {data?.id ? (
                    <>
                      <HStack spacing={5}>
                        <IoPerson />
                        <Text fontSize="large" fontWeight="extrabold">
                          <Link href={RoutePaths.DASHBOARD}>
                            Back to Dashboard
                          </Link>
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    <>
                      <HStack spacing={5}>
                        <IoPerson />
                        <Text fontSize="large" fontWeight="extrabold">
                          <Link href={RoutePaths.LOGIN}>Login</Link>
                        </Text>
                      </HStack>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

export default FaqHeader;