import React from 'react'
import Signup from '../../components/auth/signup'


const SignupPage = () => {
  return (
    <Signup/>
  )
}

export default SignupPage