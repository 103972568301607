
import Header from "../../components/home/header";
import Unleash from "../../components/home/unleash";


const HomePage = () => {

  return (
    <>
      <Header />
      <Unleash />
    </>
  );
}

export default HomePage; 